<div class="main">
  <header class="main-container container-wrap">
    <div class="brand-wrap">
      <a class="brand-link" routerLink="/home">
        <!-- standard logo -->
        <img
          [src]="brandLogo"
          alt="CBRewards Logo"
          class="brand-standard-logo"
        />
      </a>
    </div>
    <div class="navbar">
      <button
        mat-button
        class="nav-mobile"
        aria-label="Menu icon"
        (click)="sidenav.toggle()"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <nav
        [ngClass]="{
          navshow: shouldShow,
          navhide: !shouldShow,
          'navbar-wrap': true
        }"
      >
        <ul>
          <!-- <li>
            <a
              (click)="scrollTo($event, 'main')"
              routerLink=""
              routerLinkActive="active"
              >Home</a
            >
          </li> -->
          <!-- <li>
            <a (click)="scrollTo($event, 'about-program')">About CB Refer</a>
          </li> -->
          <li>
            <a (click)="scrollTo($event, 'work-description')">How It Works</a>
          </li>
          <!-- <li><a (click)="scrollTo($event, 'promo-video')">Promo video</a></li> -->
          <li><a (click)="scrollTo($event, 'faqs')">FAQs</a></li>
          <!-- <li>
            <a (click)="redirectToOkta()">Log in</a>
          </li> -->
          <li>
            <div>
            <a class="staff-referral-btn">
              <button class="banner-btn" (click)="redirectToOkta()">
                Start a Referral
              </button></a
            ></div>
          </li>
        </ul>
        <!-- <button class="banner-btn" (click)="redirectToOkta()">Start a Referral</button> -->
      </nav>
    </div>
  </header>
</div>
<mat-sidenav #sidenav disableClose (keydown.escape)="close()">
  <div class="main-nav-responsive">
    <div class="main-container container-wrap header-brand-wrap">
      <a class="brand-link" routerLink="/home">
        <!-- standard logo -->
        <img
          [src]="brandLogo"
          alt="CBRewards Logo"
          class="brand-standard-logo"
        />
      </a>
      <button
        mat-button
        aria-label="Close icon"
        class="menu-close"
        (click)="sidenav.toggle()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ul>
      <!-- <li>
      <a
        (click)="scrollTo($event, 'main')"
        routerLink=""
        routerLinkActive="active"
        >Home</a
      >
    </li> -->
      <!-- <li>
      <a (click)="scrollTo($event, 'about-program')">About CB Refer</a>
    </li> -->
      <li>
        <a (click)="scrollTo($event, 'work-description')">How It Works</a>
      </li>
      <!-- <li><a (click)="scrollTo($event, 'promo-video')">Promo video</a></li> -->
      <li><a (click)="scrollTo($event, 'faqs')">FAQs</a></li>
      <!-- <li>
      <a (click)="redirectToOkta()">Log in</a>
    </li> -->
      <li>
        <a class="staff-referral-btn">
          <button class="banner-btn" (click)="redirectToOkta()">
            Start a Referral
          </button></a
        >
      </li>
    </ul>
  </div>
</mat-sidenav>
<app-image-cta
  [brandProperties]="brandProperties"
  [imgContent]="brandProperties.firstImgContent"
></app-image-cta>

<!-- <app-program-description
  [brandProperties]="brandProperties"
></app-program-description> -->
<app-work-description
  [brandProperties]="brandProperties"
></app-work-description>

<app-visit-anytime [brandProperties]="brandProperties"></app-visit-anytime>

<app-find-an-agent [brandProperties]="brandProperties"></app-find-an-agent>

<app-stay-updated [brandProperties]="brandProperties"></app-stay-updated>

<app-get-paid [brandProperties]="brandProperties"></app-get-paid>

<!-- <app-brand-video [brandProperties]="brandProperties"></app-brand-video> -->

<app-faq></app-faq>

<app-image-cta
  [brandProperties]="brandProperties"
  [imgContent]="brandProperties.secondImgContent"
></app-image-cta>

<footer class="footer">
  <div class="container-wrap">
    <div class="footer-wrap">
      <p>
        <img src="../../../../assets/images/footer-img.png" alt="CBR" />
      </p>
      <p>
        ©{{currentYear}} Coldwell Banker. All Rights Reserved. Coldwell Banker and the Coldwell Banker logos are trademarks of Coldwell Banker
        Real Estate LLC. The Coldwell Banker® System is comprised of company owned offices which are owned by a subsidiary of 
        Anywhere Advisors LLC and franchised offices which are independently owned and operated. The Coldwell Banker System fully 
        supports the principles of the Fair Housing Act and the Equal Opportunity Act. Your use is not required for your continued affiliation with the Company.
      </p>
    </div>
  </div>
</footer>
<a
  *ngIf="isShown"
  (click)="scrollTo($event, 'ng-star-inserted')"
  class="scrolltop"
>
  <mat-icon>keyboard_arrow_up</mat-icon>
</a>
