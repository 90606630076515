<!--Without Border-->
<section id="without-border-img-text">
  <div class="banner-img background-tint {{ imgContent.class }}">
    <div
      class="background-banner"
      [ngStyle]="{ 'background-image': 'url(' + imgContent.image_path + ')' }"
    >
      <div class="banner-text secondimg-content container-wrap">
        <div>
          <h2 class="banner-title">{{ this.imgContent.heading_text }}</h2>
          <span [innerHTML]="imgContent.span_text"></span><br />
        </div>

        <button class="banner-btn" (click)="redirectToOkta()">
          {{ this.imgContent.btn_text }}
        </button>
      </div>
    </div>
  </div>
</section>

<!--With icon-->
<!-- <section *ngIf="this.location === 'Bottom'" id="with-icon-img-text">
  <div class="banner-img">
    <div class="background-banner" [ngStyle]="{'background-image': 'url(' + this.brandProperties.cover_image + ')' }">
      <div class="banner-text">
        <div>
          <div>
            <mat-icon aria-hidden="false" aria-label=" icon" class="icon">description</mat-icon>
          </div>
          <h3 class="register-now">{{this.brandProperties.banner_btn_text}}</h3>
          <span>{{this.brandProperties.cover_span_text}}</span><br>
          <p>{{this.brandProperties.para_text}}</p>
          <div style="display: inline-flex;"> <span
              class="registration-form">{{this.brandProperties.registration_text}}</span>
            <mat-icon aria-hidden="false" aria-label=" icon" class="right-icon">keyboard_arrow_right</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!--With Border-->
<!-- <section id="with-border-img-text">
  <div class="banner-img">
    <div class="background-banner">
      <div class="banner-text">
        <div class="banner-recruit-text">
          <h2 class="banner-title">REFER. RECRUIT. REWARD.</h2>
          <span>Welcome to the Sales Associate Referral Rewards Program </span><br>
        </div>
        <div class="btn-div"> <button class="banner-btn">Recruit Now</button></div>
      </div>
    </div>
  </div>
</section> -->
