import { Component, Input, OnInit } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { MatIconModule } from '@angular/material/icon';
@Component({
  selector: 'app-work-description',
  templateUrl: './work-description.component.html',
  styleUrls: ['./work-description.component.scss']
})
export class WorkDescriptionComponent implements OnInit {
  @Input() brandProperties: any;

  slides: any;

  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    load: 3,
    interval: { timing: 4000, initialDelay: 1000 },
    loop: true,
    point: {
      visible: true
    },
    touch: true,
    velocity: 0.2

  }
  constructor() { }

  ngOnInit(): void {
    this.slides = this.brandProperties.carouselContent;
  }
  
}
