<!-- <ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div id="referral-container" class="referral container" *ngIf="agentDetail && !loggedOut">
  <app-header [agentDetail]="agentDetail" (loggedOut)="disableReferralApp($event)"></app-header>
  <div class="referral-container">
    <div *ngIf="agentHasDashRoles" class="referral-container-left-nav">
      <app-left-navigation [agentDetail]="agentDetail"></app-left-navigation>
    </div>
    <div [ngClass]="getClass()">
      <router-outlet *ngIf="agentHasDashRoles"></router-outlet>
      <div *ngIf="!agentHasDashRoles" class="agent-no-roles-message align-center">
        <h2 class="title-header">Please Contact Your Help Desk</h2>
        <div class="agent-no-roles-message-wrapper">
          You are not authorized to access this application.<br />
          If you believe you have received this message in error,<br />
          please contact us at
          <a>{{email}} or {{phoneNo}}.</a>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>
<router-outlet *ngIf="loggedOut"></router-outlet> -->
<router-outlet></router-outlet>
<app-home></app-home>