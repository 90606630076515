<section class="about-program">
  <div class="main-container container-wrap">
    <div class="img-text-section">
      <div class="right-container mobile-version">
        <div class="about-text">
          <h2 class="title-heading">
            {{ brandProperties.visitAnytimeTitleMob }}
          </h2>
        </div>
        <p
          class="about-para"
          [innerHTML]="brandProperties.visitAnytimeParagraph1"
        ></p>
        <p
          class="about-para"
          [innerHTML]="brandProperties.visitAnytimeParagraph2"
        ></p>
        <button class="banner-btn" (click)="redirectToOkta()">
          Start a Referral
        </button>
      </div>
      <div class="visit-img">
        <img src="../../../../../assets/images/additional-screen-img.png"/>
      </div>
      <div class="right-container desktop-version">
        <div class="about-text">
          <h2
            class="title-heading"
            [innerHTML]="brandProperties.visitAnytimeTitle"
          ></h2>
        </div>
        <p
          class="about-para"
          [innerHTML]="brandProperties.visitAnytimeParagraph1"
        ></p>
        <p
          class="about-para"
          [innerHTML]="brandProperties.visitAnytimeParagraph2"
        ></p>
        <button class="banner-btn" (click)="redirectToOkta()">
          Start a Referral
        </button>
      </div>
    </div>
  </div>
</section>
