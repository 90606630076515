import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from './core/services/app-config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AgentDetails } from './core/models/AgentDetails';
import { AE_WEB_CONST } from './core/models/constants';
import { MatDialog } from '@angular/material/dialog';

/** Base application component - builds the site structure */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  /** getting brand of the application */
  brandClass = AppConfigService.brandingData.brandClass;
  baseUrl = AppConfigService.brandingData.baseUrl;
  agentDetail: AgentDetails | any;
  agentHasDashRoles: boolean;
  phoneNo = '1-877-426-5393';
  email = 'help@coldwellbanker.com';
  agentDetailsData: any;

  /** Component instantiation */
  constructor(
    private readonly cookieService: CookieService,
    public readonly router: ActivatedRoute,
    private routing: Router,
    private readonly appConfig: AppConfigService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog
  ) {
    this.spinner.show();
    document.querySelector('body').classList.add(this.brandClass);
  }

  /** Component Angular initialization life cycle hook */
  ngOnInit() {
    const sessionCookie = this.cookieService.get('car-ses-tok');
    setTimeout(() => {
      if (this.router.snapshot.queryParams['code'] && !sessionCookie) {
        this.redirectToReferral();
        // this.spinner.show();
        // const authCode = this.router.snapshot.queryParams['code'];
        // let baseTokenUrl = <string>this.appConfig.getConfig('tokenUrl');
        // baseTokenUrl = baseTokenUrl.replace('authCode', authCode);
        //  this.getAccessToken(baseTokenUrl);
      } else if (this.routing.url === '/logout') {
        this.spinner.hide();
        //  this.loggedOut = true;
        this.routing.navigate(['logout']);
      } else if (!sessionCookie) {
        this.spinner.show();
        //  this.redirectToOkta();
      } else {
        this.spinner.show();
        this.cookieService.deleteAll();
        //  this.redirectToOkta();
        // sessionCookie ? this.getAgentDetails() : this.redirectToOkta();
      }
    }, 1000);
  }
  removeSession() {
    let redirectURL = <string>this.appConfig.getConfig('logoutUrl');
    redirectURL = redirectURL.replace('idToken', this.cookieService.get('car-ses-id'));
    this.routing.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
      skipLocationChange: true
    });
  }

  redirectToOkta() {
    const redirectURL = this.appConfig.getConfig('redirectUrl');
    this.routing.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
      skipLocationChange: true
    });
  }

  redirectToReferral() {
    const redirectURL = this.appConfig.getConfig('referralUrl');
    this.routing.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
      skipLocationChange: true
    });
  }




}
