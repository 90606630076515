<div class="logout">
  <div class="logout-container align-center">
    <div class="logout-container-brand">
      <img [src]="brandLogo" />
    </div>
    <h2 class="logout-container-title">You are now logged out of CB Refer</h2>
    <p><a (click)="redirectToOkta()">Click here</a> to login again</p>
  </div>
  <div class="logout-footer">
    <footer class="footer">
      <div class="container">
        <div class="footer-copyright-container">
          <span>© 2000-2021 Coldwell Banker Real Estate LLC.</span>
        </div>
        <!-- <div class="footer-menu-container">
          <ul>
            <li>
              <a>Terms of Use</a>
            </li>
            <li>
              <a>Privacy Policy</a
              >
            </li>
          </ul>
        </div> -->
      </div>
    </footer>
  </div>
</div>
