<section class="about-program">
  <div class="main-container container-wrap">
    <div class="img-text-section">
      <div class="right-container">
        <div class="about-text">
          <h2
            class="title-heading"
            [innerHTML]="brandProperties.findAnAgentTitle"
          ></h2>
        </div>
        <p class="about-para">{{ this.brandProperties.findAnAgentContent }}</p>
        <button class="banner-btn" (click)="redirectToOkta()">
          START A REFERRAL
        </button>
      </div>
      <div class="findAgent-img">
        <img src="../../../../../assets/images/map.png" />
      </div>
    </div>
  </div>
</section>
