import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-find-an-agent',
  templateUrl: './find-an-agent.component.html',
  styleUrls: ['./find-an-agent.component.scss']
})
export class FindAnAgentComponent implements OnInit {
  @Input() brandProperties: any;

  constructor(private appConfig: AppConfigService,
    private routing: Router) { }

  ngOnInit(): void {
  }

  redirectToOkta() {
    const redirectURL = this.appConfig.getConfig('redirectUrl');
    this.routing.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
      skipLocationChange: true,
    });
  }

}
