import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-stay-updated',
  templateUrl: './stay-updated.component.html',
  styleUrls: ['./stay-updated.component.scss'],
})
export class StayUpdatedComponent implements OnInit {
  @Input() brandProperties: any;

  constructor(private appConfig: AppConfigService, private routing: Router) {}

  ngOnInit(): void {}
  redirectToOkta() {
    const redirectURL = this.appConfig.getConfig('redirectUrl');
    this.routing.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
      skipLocationChange: true,
    });
  }
}
