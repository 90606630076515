<section class="about-program">
  <div class="main-container container-wrap">
    <div class="img-text-section">
      <div class="visit-img">
        <img src="../../../../../assets/images/promptly-img.png" />
      </div>
      <div class="right-container">
        <div class="about-text">
          <h2 class="title-heading">{{ this.brandProperties.getPaidTitle }}</h2>
        </div>
        <p class="about-para">{{ this.brandProperties.getPaidContent }}</p>
        <div class="mob-respo-btn">
          <button class="banner-btn" (click)="redirectToOkta()">
            Start a Referral
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
