<div class="main faqs">
  <div class="container-wrap">
    <h2 class="title-left">FAQ:</h2>
    <mat-accordion multi>
      <mat-expansion-panel hideToggle #faq1>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p *ngIf="!faq1.expanded" class="acc-icon">+</p>
            <p *ngIf="faq1.expanded" class="acc-icon">-</p>
            What is CB Refer for?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="panel-data">
          CB Refer makes it easy to refer a client to another Coldwell Banker
          agent. Our CB Refer specialists will match your referral with an agent
          in the Coldwell Banker Network, where coverage is available.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle #faq2>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p *ngIf="!faq2.expanded" class="acc-icon">+</p>
            <p *ngIf="faq2.expanded" class="acc-icon">-</p>
            Who can use CB Refer?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="panel-data">
          CB Refer is available for the Coldwell Banker Realty real estate agent
          population.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle #faq3>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p *ngIf="!faq3.expanded" class="acc-icon">+</p>
            <p *ngIf="faq3.expanded" class="acc-icon">-</p>
            Where should I go if I need help?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="panel-data need-help-sec">
          If you have questions about CB Refer technology, please contact the
          Helpdesk or email
          <a href="mailTo:questions@cbrefer.com">questions@cbrefer.com</a
          >.<br />
          <strong>Coldwell Banker Helpdesk</strong>

          <span>Phone: <a href="tel:877-NRT-HELP">877-NRT-HELP</a> </span>
          <span
            >Email:
            <a href="mailTo:cbhelp@realogy.com">cbhelp@realogy.com</a></span
          >
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle #faq4>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p *ngIf="!faq4.expanded" class="acc-icon">+</p>
            <p *ngIf="faq4.expanded" class="acc-icon">-</p>
            Why is using CB Refer better than handling a referral on my own?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="panel-data">
          CB Refer delivers you and your clients an excellent experience by staying within 
          the Coldwell Banker Network. Agents using CB Refer to place their referrals are 
          paid quickly and earn an average commission of more than $2800.
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
