export const AE_WEB_CONST = {
  referralSubmit: `Submit Referral To`,
  referralDetails: `Your Referral Details`,
  /** Title to display copyright*/
  copyright: `&copy; 2000-2019 Cartus Corporation`,
  /** state list mock json */
  stateList: [
    { name: `Alabama`, shortName: `AL` },
    { name: `Alaska`, shortName: `AK` },
    { name: `Arizona`, shortName: `AZ` },
    { name: `Arkansas`, shortName: `AR` },
    { name: `California`, shortName: `CA` },
    { name: `Colorado`, shortName: `CO` },
    { name: `Connecticut`, shortName: `CT` },
    { name: `Delaware`, shortName: `DE` },
    { name: `District of Columbia`, shortName: `DC` },
    { name: `Florida`, shortName: `FL` },
    { name: `Georgia`, shortName: `GA` },
    { name: `Hawaii`, shortName: `HI` },
    { name: `Idaho`, shortName: `ID` },
    { name: `Illinois`, shortName: `IL` },
    { name: `Indiana`, shortName: `IN` },
    { name: `Iowa`, shortName: `IA` },
    { name: `Kansa`, shortName: `KS` },
    { name: `Kentucky`, shortName: `KY` },
    { name: `Lousiana`, shortName: `LA` },
    { name: `Maine`, shortName: `ME` },
    { name: `Maryland`, shortName: `MD` },
    { name: `Massachusetts`, shortName: `MA` },
    { name: `Michigan`, shortName: `MI` },
    { name: `Minnesota`, shortName: `MN` },
    { name: `Mississippi`, shortName: `MS` },
    { name: `Missouri`, shortName: `MO` },
    { name: `Montana`, shortName: `MT` },
    { name: `Nebraska`, shortName: `NE` },
    { name: `Nevada`, shortName: `NV` },
    { name: `New Hampshire`, shortName: `NH` },
    { name: `New Jersey`, shortName: `NJ` },
    { name: `New Mexico`, shortName: `NM` },
    { name: `New York`, shortName: `NY` },
    { name: `North Carolina`, shortName: `NC` },
    { name: `North Dakota`, shortName: `ND` },
    { name: `Ohio`, shortName: `OH` },
    { name: `Oklahoma`, shortName: `OK` },
    { name: `Oregon`, shortName: `OR` },
    { name: `Pennsylvania`, shortName: `PA` },
    { name: `Rhode Island`, shortName: `RI` },
    { name: `South Carolina`, shortName: `SC` },
    { name: `South Dakota`, shortName: `SD` },
    { name: `Tennessee`, shortName: `TN` },
    { name: `Texas`, shortName: `TX` },
    { name: `Utah`, shortName: `UT` },
    { name: `Vermont`, shortName: `VT` },
    { name: `Virginia`, shortName: `VA` },
    { name: `Washington`, shortName: `WA` },
    { name: `West Virginia`, shortName: `WV` },
    { name: `Wisconsin`, shortName: `WI` },
    { name: `Wyoming`, shortName: `WY` },
  ],
  /** property types json */
  propertyTypes: [
    { name: `Single Family`, value: 1 },
    { name: `Townhouse`, value: 4 },
    { name: `New Construction`, value: 12 },
    { name: `Condo / Co-op`, value: 5 },
    { name: `Commercial`, value: 9 },
    { name: `Other`, value: 6 },
    { name: 'Lot / Land', value: 11 },
  ],
  /** radius dropdown values */
  withinRadiusValues: [
    { name: `Any`, value: 0 },
    { name: `5 miles`, value: 5 },
    { name: `10 miles`, value: 10 },
    { name: `20 miles`, value: 20 },
    { name: `50+ miles`, value: 50 },
  ],
  /** number of rooms list */
  noOfRooms: [
    { name: `Any`, value: 0 },
    { name: `1+`, value: 1 },
    { name: `2+`, value: 2 },
    { name: `3+`, value: 3 },
    { name: `4+`, value: 4 },
    { name: `5+`, value: 5 },
  ],
  /** receiving agent referral fee dropdown values */
  referralFeeList: [
    { name: `10%`, value: 10 },
    { name: `15%`, value: 15 },
    { name: `20%`, value: 20 },
    { name: `25%`, value: 25 },
    { name: `30%`, value: 30 },
    { name: `35%`, value: 35 },
  ],
  /** ownership types list */
  ownershipTypes: [
    { name: 'N/A', value: 0 },
    { name: `Primary Residence`, value: 4 },
    { name: `Vacation Home`, value: 3 },
    { name: `Secondary Home`, value: 2 },
    { name: `Rental Property`, value: 1 },
  ],
  validationMessages: {
    customerFirstName: {
      required: `Please enter First Name.`,
      pattern: `Please use only letters, a space, and -' characters.`,
    },
    customerLastName: {
      required: `Please enter Last Name.`,
      pattern: `Please use only letters, a space, and -' characters.`,
    },
    customerEmail: {
      required: `Please enter a valid email address.`,
      pattern: `Please enter a valid email address.`,
      maxlength: `Please enter a valid email address.`,
    },
    customerPhone: {
      required: `Please enter Phone.`,
      pattern: `Please enter a valid Phone.`,
      minLength: `Please enter a valid Phone.`,
    },
    customerStreetAddress: {
      required: `Please enter Mailing Street Address.`,
      maxlength: `Please enter Mailing Street Address.`,
    },
    customerCity: {
      required: `Please enter City.`,
      maxlength: `Please enter City.`,
    },
    customerState: {
      required: `Please enter a State.`,
    },
    customerZipCode: {
      required: `Please enter Zip.`,
      zipError: `Please enter a valid Zip.`,
    },
    referringAgentFirstName: {
      required: `Please enter First Name.`,
      pattern: `Please use only letters, a space, and -' characters.`,
    },
    referringAgentLastName: {
      required: `Please enter Last Name.`,
      pattern: `Please use only letters, a space, and -' characters.`,
    },
    referringAgentEmail: {
      required: `Please enter a valid email address.`,
      pattern: `Please enter a valid email address.`,
      maxlength: `Please enter a valid email address.`,
    },
    referringAgentPhone: {
      required: `Please enter Phone.`,
      pattern: `Please enter a valid Phone.`,
      minLength: `Please enter a valid Phone.`,
    },
    customerPreference: {
      required: `Please select`,
    },
    buyingCity: {
      required: `Please enter Destination City.`,
    },
    buyingState: {
      required: `Please enter Destination State.`,
    },
    maximumPrice: {
      required: `Please enter Price Range Maximum.`,
      invalid: `Price Range Maximum cannot be less than 0 or Price Range Minimum.`,
    },
    houseHuntingStartDate: {
      required: `Please enter Start Date.`,
      invalidDate: `Start Date cannot be less than current date.`,
      matDatepickerParse: `Please enter valid Start Date.`,
      matDatepickerMin: `Please enter valid Start Date.`,
    },
    houseHuntingEndDate: {
      required: `Please enter End Date.`,
      invalidDate: `End Date cannot be less than Start Date.`,
      matDatepickerParse: `Please enter valid End Date.`,
      matDatepickerMin: `Please enter valid End Date.`,
    },
    buyingPropertyTypes: {
      required: `Please enter Property Type.`,
    },
    sellingStreetAddress: {
      required: `Please enter Street Address.`,
    },
    sellingCity: {
      required: `Please enter City.`,
    },
    sellingState: {
      required: `Please enter State.`,
    },
    sellingZipCode: {
      required: `Please enter Zip.`,
      zipError: `Please enter a valid Zip.`,
    },
    sellingPropertyType: {
      required: `Please enter Property Type.`,
    },
    sellingOwnershipType: {
      required: `Please enter Ownership Type.`,
    },
    receivingAgentFirstName: {
      required: `Please enter First Name.`,
      pattern: `Please use only letters, a space, and -' characters.`,
    },
    receivingAgentLastName: {
      required: `Please enter First Name.`,
      pattern: `Please use only letters, a space, and -' characters.`,
    },
    receivingAgentCompany: {
      required: `Please enter Company.`,
      maxlength: `Please enter Company.`,
    },
    receivingAgentEmail: {
      required: `Please enter a valid email address.`,
      pattern: `Please enter a valid email address.`,
      maxlength: `Please enter a valid email address.`,
    },
    receivingAgentPhone: {
      required: `Please enter Phone.`,
      pattern: `Please enter a valid Phone.`,
      minLength: `Please enter a valid Phone.`,
    },
    receivingAgentCity: {
      required: `Please enter City.`,
      maxlength: `Please enter City.`,
    },
    receivingAgentState: {
      required: `Please enter a state.`,
    },
    receivingAgentZip: {
      required: `Please enter Zip.`,
      zipError: `Please enter a valid Zip.`,
    },
  },
  formErrors: {
    customerFirstName: ``,
    customerLastName: ``,
    customerEmail: ``,
    customerPhone: ``,
    customerPhoneType: ``,
    customerStreetAddress: ``,
    customerCity: ``,
    customerState: ``,
    customerZipCode: ``,
    customerPreference: ``,
    referringAgentFirstName: ``,
    referringAgentLastName: ``,
    referringAgentEmail: ``,
    referringAgentPhone: ``,
    buyingCity: ``,
    buyingState: ``,
    maximumPrice: ``,
    houseHuntingStartDate: ``,
    houseHuntingEndDate: ``,
    sellingStreetAddress: ``,
    sellingCity: ``,
    sellingState: ``,
    sellingZipCode: ``,
    receivingAgentFirstName: ``,
    receivingAgentLastName: ``,
    receivingAgentCompany: ``,
    receivingAgentEmail: ``,
    receivingAgentPhone: ``,
    receivingAgentCity: ``,
    receivingAgentState: ``,
    receivingAgentZip: ``,
  },
  submitMessages: {
    successMessage: `We will be in touch with you shortly to connect you and your customer with a STAR agent.`,
    errorMessage: `Sorry we had an issue submitting your request.
      Please contact us at <span>xxx-xxx-xxxx or email@email.com</span>
      for assistance or try again in a few minutes.`,
  },
  errorMessageForDashRoles: {
    error: `You are not authorized to access this application. If you
       believe you have received this message in error, please contact us at email@email.com or xxx-xxx-xxxx.`,
  },
  saveMessages: {
    successMessage: `Your progress has been saved as a draft.`,
    errorMessage: `We are unable to process your request at this time.<br/> Please try again later.`,
    maxDraftMessage: `An error occurred while save referral: Referral Draft maximum count is 20`,
    maxDraftErrorMessage: `Referral draft limit of 20 drafts has been reached.<br/> Draft cannot be saved.`,
  },
  getDraft: {
    errorMessage: `We are unable to process your request at this time.<br/> Please try again later.`,
  },
  deleteDraft: {
    confirmMessage: `Are you sure you want to delete this draft? <br />This action cannot be undone.`,
    successMessage: `Draft has been successfully deleted.`,
    errorMessage: `We are unable to process your request at this time.<br/> Please try again later.`,
  },
  warningMessages: {
    dirtyCheckConfirmation: `Please note: You have unsaved changes that may be lost. Are you sure you want continue?`,
  },
  sessionWarningMessage: {
    confirmationMessage: `Your session is about to expire. To stay logged in, please click OK.`,
  },
  mobileWidth: 415,
  CB_Brand: `coldwellbanker`,
  cbProperties: {
    image: `test`,
    videoLink: 'https://cartus.qumucloud.com/view/1TNTXDL419q?autoplay=0',
    slider_image: `./assets/images/banner-img.png`,
    cover_image: `./assets/images/testimonials-bgrnd9.jpg`,
    // span_text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
    // heading_text: `tincidunt. eget. nullam.`,
    // btn_text: `vestibulum sed`,
    cover_span_text: `tortor at risus?`,
    para_text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
     do eiusmod tempor incididunt ut labore et dolore magna aliqua. `,
    banner_btn_text: `vestibulum sed`,
    registration_text: `vestibulum sed`,
    step_1: `./assets/icons/bulb.png`,
    step_2: `./assets/icons/regiformicon.png`,
    step_3: `./assets/icons/earnincome.png`,
    howItWorksTitle: `Grow your referral business in just 3 easy steps:`,
    howItWorksFirstCardName: `Start at CBRefer.com`,
    // howItWorksSecondCardName: `Find an agent`,
    howItWorksThirdCardName: `Monitor progress`,
    howItWorksFourthCardName: `Get paid promptly`,
    howItWorksFirstCardContent: `Use the Start a Referral button<br> to begin the referral process.<br>
    A CB Refer specialist will<br> find the best agent for your<br> referral.`,
    howItWorksSecondCardContent: `CB Refer then finds qualified<br> Coldwell Banker agents in<br> your client’s new area.`,
    howItWorksThirdCardContent: `We’ll monitor your referral and<br> provide milestone updates as<br> they happen,
     keeping you<br> updated every step of the<br> way.`,
    howItWorksFourthCardContent: `When the CB agent closes the<br> transaction, you are promptly<br> paid.`,
    visitAnytimeTitle: `Visit CBRefer.com <br> anytime.`,
    visitAnytimeTitleMob: `Visit CBRefer.com anytime.`,
    visitAnytimeParagraph1: `Submitting a referral has never been so easy. People are moving at record levels today and want
     immediate support whether they are buying and selling a home across town or across the country.`,
    visitAnytimeParagraph2: `Visit CBRefer.com or Desk to get started by answering a few questions about your client
     and their home buying or selling needs.`,
    findAnAgentTitle: `CB Refer makes it easy<br> to connect with an<br> agent, anywhere.`,
    findAnAgentContent: `Using CB Refer's agent match criteria, one of our specialists will match your referral with a trusted agent
     within the Coldwell Banker Network. Feel great about sending referrals nationwide knowing that your referral is in good hands.`,
    stayUpdatedTitle: `Stay updated every step<br> of the way.`,
    stayUpdatedContent: `Tired of being kept in the dark about the status of your referral? You  don’t have to worry about that with CB
     Refer. We’ll monitor your referral and provide milestone updates as they happen, keeping you  updated every step of the way.`,
    stayUpdatedImg: `./assets/images/third-banner.png`,
    getPaidTitle: `Get paid promptly.`,
    getPaidContent: `When the Coldwell Banker agent closes the transaction, you are automatically paid. No more tracking where your
     payment is or waiting weeks to get paid. We’ll handle this process for you.`,
    video_heading_text: `Learn more<br> about CB Refer.`,
    video_content_text: `Get all the details about the new program so you can start earning referral income and growing your business in new ways.`,
    firstImgContent: {
      // span_text: `A referral program built <em>for</em> you—<em>by</em> you.`,
      heading_text: `Welcome to CB Refer.`,
      btn_text: `Start a Referral`,
      image_path: `./assets/images/banner-img.png`,
    },
    secondImgContent: {
      span_text: '',
      heading_text: `Expand your business with CB Refer.`,
      btn_text: `Start a Referral`,
      image_path: `./assets/images/second-banner-img.png`,
      class: 'secondBannerImg',
    },
    carouselContent: [
      {
        image: '../../../../../assets/icons/start.png',
        hoverImage: '../../../../../assets/icons/start-white-icon.png',
        title: 'Start at CBRefer.com',
        description: `Use the Start a Referral button<br> to begin the referral process.<br> 
        We’ll ask about your client’s<br> needs and the fee structure<br> you prefer.`,
      },
      // {
      //   image: '../../../../../assets/icons/agent-black-icon.png',
      //   hoverImage: '../../../../../assets/icons/agent.png',
      //   title: 'Find an agent',
      //   description: `CB Refer then finds qualified<br> Coldwell Banker agents in<br> your client’s new area.`,
      // },
      {
        image: '../../../../../assets/icons/progress.png',
        hoverImage: '../../../../../assets/icons/progress-white-icon.png',
        title: 'Monitor progress',
        description: `We’ll monitor your referral and<br> provide milestone updates as<br> they happen, 
        keeping you<br> updated every step of the<br> way.`,
      },
      {
        image: '../../../../../assets/icons/promptly.png',
        hoverImage: '../../../../../assets/icons/promptly-paide-white-icon.png',
        title: 'Get paid promptly',
        description: `When the CB agent closes the<br> transaction, you are promptly<br> paid.`,
      },
    ],
  },
  commentsLength: 255,
  minPriceRange: [
    { name: 'No Minimum', value: 'none' },
    { name: '$100,000', value: 100000 },
    { name: '$200,000', value: 200000 },
    { name: '$300,000', value: 300000 },
    { name: '$400,000', value: 400000 },
    { name: '$500,000', value: 500000 },
    { name: '$600,000', value: 600000 },
    { name: '$700,000', value: 700000 },
    { name: '$800,000', value: 800000 },
    { name: '$900,000+', value: 900000 },
  ],
  maxPriceRange: [
    { name: '$100,000', value: 100000 },
    { name: '$200,000', value: 200000 },
    { name: '$300,000', value: 300000 },
    { name: '$400,000', value: 400000 },
    { name: '$500,000', value: 500000 },
    { name: '$600,000', value: 600000 },
    { name: '$700,000', value: 700000 },
    { name: '$800,000', value: 800000 },
    { name: '$900,000', value: 900000 },
    { name: '$1,000,000+', value: 1000000 },
    { name: 'No Maximum', value: 'none' },
  ],
  estimationValues: [
    { name: 'No Estimated Value', value: 'none' },
    { name: '$50,000', value: 50000 },
    { name: '$100,000', value: 100000 },
    { name: '$150,000', value: 150000 },
    { name: '$200,000', value: 200000 },
    { name: '$250,000', value: 250000 },
    { name: '$300,000', value: 300000 },
    { name: '$350,000', value: 350000 },
    { name: '$400,000', value: 400000 },
    { name: '$450,000', value: 450000 },
    { name: '$500,000', value: 500000 },
    { name: '$600,000', value: 600000 },
    { name: '$700,000', value: 700000 },
    { name: '$800,000', value: 800000 },
    { name: '$900,000', value: 900000 },
    { name: '$1,000,000+', value: 1000000 },
  ],
  stepsMapping: {
    first: 1,
    second: 2,
    third: 3,
    fourth: 4,
    fifth: 5,
    sixth: 6,
    seventh: 7,
    eighth: 8,
    ninth: 9,
    tenth: 10,
    eleventh: 11,
    twelveth: 12,
    thirteenth: 13,
  },
  referralTypeChange: `Changing your type of referral will clear your data and start the process over.`,
};
