import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-visit-anytime',
  templateUrl: './visit-anytime.component.html',
  styleUrls: ['./visit-anytime.component.scss']
})
export class VisitAnytimeComponent implements OnInit {
  @Input() brandProperties: any;

  constructor(private router: Router,
    private appConfig: AppConfigService) { }

  ngOnInit(): void {
  }
  redirectToOkta() {
    const redirectURL = <string>this.appConfig.getConfig('redirectUrl');
    this.router.navigate(['/externalRedirect', { externalUrl: redirectURL }], {
      skipLocationChange: true
    });
  }
}
