<section id="without-border-img-text">
  <div class="banner-img background-tint container-wrap">
    <div
      class="background-banner"
      [ngStyle]="{
        'background-image': 'url(' + brandProperties.stayUpdatedImg + ')'
      }"
    >
      <div class="banner-text">
        <div>
          <h2
            class="banner-title"
            [innerHTML]="brandProperties.stayUpdatedTitle"
          ></h2>
          <span [innerHTML]="brandProperties.stayUpdatedContent"></span><br />
        </div>
        <!-- <button class="banner-btn" >Find an Agent</button> -->
      </div>
    </div>
  </div>
</section>
