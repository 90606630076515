import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { LogoutComponent } from './core/components/logout/logout.component';
import { ExternRouteComponent } from './core/components/extern-route/extern-route.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { APP_BASE_HREF, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './core/components/home/home.component';
import { ImageCtaComponent } from './core/components/home/image-cta/image-cta.component';
import { VisitAnytimeComponent } from './core/components/home/visit-anytime/visit-anytime.component';
import { WorkDescriptionComponent } from './core/components/home/work-description/work-description.component';
import { BrandVideoComponent } from './core/components/home/brand-video/brand-video.component';
import { FaqComponent } from './core/components/faq/faq.component';
import { environment } from 'src/environments/environment';
import { ShortNumberPipe } from './core/pipes/short-number.pipe';
import { FindAnAgentComponent } from './core/components/home/find-an-agent/find-an-agent.component';
import { StayUpdatedComponent } from './core/components/home/stay-updated/stay-updated.component';
import { GetPaidComponent } from './core/components/home/get-paid/get-paid.component';
import { NguCarouselModule } from '@ngu/carousel';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    ExternRouteComponent,
    HomeComponent,
    ImageCtaComponent,
    VisitAnytimeComponent,
    WorkDescriptionComponent,
    BrandVideoComponent,
    FaqComponent,
    ShortNumberPipe,
    FindAnAgentComponent,
    StayUpdatedComponent,
    GetPaidComponent
  ],
  imports: [
    BrowserAnimationsModule,
    NoopAnimationsModule,
    BrowserModule,
    MaterialModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    NgxSpinnerModule,
    NguCarouselModule,
    MatIconModule
  ],
  providers: [CookieService, CurrencyPipe, DatePipe, { provide: APP_BASE_HREF, useValue: '/' }],
  entryComponents: [],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
