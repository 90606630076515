<section class="work-description">
  <div class="main-container">
    <div class="about-text">
      <h2 class="title-heading how-it-works-text">{{this.brandProperties.howItWorksTitle}}</h2>
    </div>
    <div class="container">
      <!----Carousel for Mobile responsive-->
      <div class="carousel-slider">

        <ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="slides">
          <ngu-tile *nguCarouselDef="let slide;" class="item">
            <div class="mat-card-details" >
              <mat-card class="example-card mat-card-div">
                <div class="start-img"><img [src]="slide.image" [alt]="slide.title"></div>
                <div class="start-img-hover"><img [src]="slide.hoverImage" [alt]="slide.title"></div>
                <mat-card-content>
                  <h4>{{slide.title}}</h4>
                  <p [innerHTML]="slide.description">
                  </p>
                </mat-card-content>
              </mat-card>
            </div>
          </ngu-tile>
          <button NguCarouselPrev class="leftRs" [style.opacity]="myCarousel.isFirst ? 0.5:1">
            <mat-icon _ngcontent-ysf-c28="" role="img"
              class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color ng-star-inserted custom-arrow"
              aria-hidden="true" data-mat-icon-type="font">keyboard_arrow_left</mat-icon>
          </button>
          <ul class="myPoint" NguCarouselPoint>
            <li *ngFor="let i of myCarousel.pointNumbers; let j = index" [class.active]="j==myCarousel.activePoint"
              (click)="myCarousel.moveTo(i)"></li>
          </ul>
          <button NguCarouselNext class="rightRs" [style.opacity]="myCarousel.isLast ? 0.5:1">
            <mat-icon _ngcontent-ysf-c28="" role="img"
              class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color ng-star-inserted custom-arrow"
              aria-hidden="true" data-mat-icon-type="font">keyboard_arrow_right</mat-icon>
          </button>
        </ngu-carousel>
      </div>
      <div id="steps-card1">
        <div class="mat-card-details">
          <mat-card class="example-card mat-card-div">
            <!-- <div><mat-icon aria-hidden="false" aria-label=" icon" class="icon">description</mat-icon></div> -->
            <div class="start-img"><img src="../../../../../assets/icons/start.png"></div>
            <div class="start-img-hover"><img src="../../../../../assets/icons/start-white-icon.png"></div>
            <mat-card-content>
              <h4>{{this.brandProperties.howItWorksFirstCardName}}</h4>
              <p [innerHTML]="brandProperties.howItWorksFirstCardContent">
              </p>
            </mat-card-content>
          </mat-card>
        </div>
        <!-- <div class="mat-card-details">
          <mat-card class="example-card mat-card-div">
            <div class="agent-img"><img src="../../../../../assets/icons/agent-black-icon.png"></div>
            <div class="agent-img-hover"><img src="../../../../../assets/icons/agent.png"></div>
            <mat-card-content>
              <h4>{{this.brandProperties.howItWorksSecondCardName}}</h4>
              <p [innerHTML]="brandProperties.howItWorksSecondCardContent">
              </p>
            </mat-card-content>
          </mat-card>
        </div> -->
        <div class="mat-card-details">
          <mat-card class="example-card mat-card-div">
            <div class="progress-img"><img src="../../../../../assets/icons/progress.png"></div>
            <div class="progress-img-hover"><img src="../../../../../assets/icons/progress-white-icon.png"></div>
            <mat-card-content>
              <h4> {{this.brandProperties.howItWorksThirdCardName}}</h4>
              <p [innerHTML]="brandProperties.howItWorksThirdCardContent">
              </p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="mat-card-details">
          <mat-card class="example-card mat-card-div">
            <div class="promptly-img"><img src="../../../../../assets/icons/promptly.png"></div>
            <div class="promptly-img-hover"><img src="../../../../../assets/icons/promptly-paide-white-icon.png"></div>
            <mat-card-content>
              <h4>{{this.brandProperties.howItWorksFourthCardName}}</h4>
              <p [innerHTML]="brandProperties.howItWorksFourthCardContent">
              </p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</section>